//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import monobank from '~/assets/img/icons/monobank.svg';
import ProductPdp from '@/components/pages/productCard/ProductContent/Components/ProductPdp/index';
import ProductBuyFavorite from '@/components/pages/productCard/ProductContent/Components/ProductBuyFavorite/index';
import ProductSizes from '@/components/pages/productCard/ProductContent/Components/ProductSizes/index';
import SizeAdvice from '@/components/pages/productCard/ProductContent/Components/SizeAdvice';

export default {
    name: "ProductInfo",
    data() {
        return {
            monobank: monobank,
        }
    },
}
