//
//
//
//
//
//
//
//

export default {
    name: "ProductBuyError",
    props: {
        message: {
            type: String,
            required: true
        }
    }
}
