//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SizeAdvice',
    props: {
        adviceSizeData: {
            type: Object,
            default: null
        },
    }
};
